import Articles1 from '../img/articles/Articles1.webp';
import Articles2 from '../img/articles/Articles2.webp';
import Articles3 from '../img/articles/Articles3.webp';
import Articles4 from '../img/articles/Articles4.webp';
import Articles5 from '../img/articles/Articles5.webp';
import Articles6 from '../img/articles/Articles6.webp';
import Articles7 from '../img/articles/Articles7.webp';
import Articles8 from '../img/articles/Articles8.webp';
import Articles9 from '../img/articles/Articles9.webp';
import Articles10 from '../img/articles/Articles10.webp';
import Articles11 from '../img/articles/Articles11.jpg';
import Articles11_2 from '../img/articles/Articles11_2.jpg';
import Articles12 from '../img/articles/Articles12.jpg';
import Articles12_2 from '../img/articles/Articles12_2.jpg';


const articlesData = [
    {
      id: 1,
      category: 'Фестивали и премии',
      title: 'Победители «Золотого глобуса — 2024». Триумф «Медведя» и «Оппенгеймера»',
      date: '8 января 2024',
      image: Articles1,
      content: [
        { type: 'paragraph', text: 'В Беверли-Хиллз прошла церемония вручения премии «Золотой глобус». Лучшим драматическим фильмом стал «Оппенгеймер» Кристофера Нолана (рекордсмен с пятью наградами), комедией — «Бедные-несчастные» Йоргоса Лантимоса (два приза), а статуэтка за иностранный фильм ушла «Анатомии падения» Жюстин Трие (две премии). В сериальной категории победу одержали «Наследники» (4), «Медведь» (3) и «Грызня» (3).' },
        { type: 'paragraph', text: 'В этом году в каждой категории представлено шесть номинантов, а не пять, как обычно. Также были добавлены две новые: «Кинематографические и кассовые достижения» («Барби») и «Лучшее выступление в жанре стендап-комедии на телевидении» (Рики Джервэйс). Ведущим мероприятия стал американский стендап-комик Джо Кой.' },
        { type: 'header', text: 'Победители в области кино' },
        { type: 'sub-header', text: 'Лучший фильм (драма)' },
        { type: 'list', text: '«Оппенгеймер»' },
        { type: 'sub-header', text: 'Остальные номинанты' },
        { type: 'list', text: '«Убийцы цветочной луны»' },
        { type: 'list', text: '«Прошлые жизни»' },
        { type: 'list', text: '«Маэстро»' },
        { type: 'list', text: '«Анатомия падения»' },
        { type: 'list', text: '«Зона интересов»' },
        { type: 'sub-header', text: 'Лучший фильм (мюзикл или комедия)' },
        { type: 'list', text: '«Бедные-несчастные»' },
        { type: 'sub-header', text: 'Остальные номинанты' },
        { type: 'list', text: '«Air: Большой прыжок»' },
        { type: 'list', text: '«Барби»' },
        { type: 'list', text: '«Американское чтиво»' },
        { type: 'list', text: '«Оставленные»' },
        { type: 'list', text: '«Май декабрь»' },
        { type: 'sub-header', text: 'Лучший актер (драма)' },
        { type: 'list', text: 'Киллиан Мёрфи, «Оппенгеймер»' },
        { type: 'sub-header', text: 'Остальные номинанты' },
        { type: 'list', text: 'Леонардо ДиКаприо, «Убийцы цветочной луны»' },
        { type: 'list', text: 'Колман Доминго, «Растин»' },
        { type: 'list', text: 'Барри Кеоган, «Солтберн»' },
        { type: 'list', text: 'Брэдли Купер, «Маэстро»' },
        { type: 'list', text: 'Эндрю Скотт, «Мы всем чужие»' },
        { type: 'sub-header', text: 'Лучшая актриса (драма)' },
        { type: 'list', text: 'Лили Гладстоун, «Убийцы цветочной луны»' },
        { type: 'sub-header', text: 'Остальные номинанты' },
        { type: 'list', text: 'Аннетт Бенинг, «Дайана Найэд»' },
        { type: 'list', text: 'Грета Ли, «Прошлые жизни»' },
        { type: 'list', text: 'Кэри Маллиган, «Маэстро»' },
        { type: 'list', text: 'Кэйли Спэни, «Присцилла: Элвис и я»' },
        { type: 'list', text: 'Сандра Хюллер, «Анатомия падения»' },
        { type: 'sub-header', text: 'Лучший режиссер' },
        { type: 'list', text: 'Кристофер Нолан, «Оппенгеймер»' },
        { type: 'sub-header', text: 'Остальные номинанты' },
        { type: 'list', text: 'Брэдли Купер, «Маэстро»' },
        { type: 'list', text: 'Грета Гервиг, «Барби»' },
        { type: 'list', text: 'Йоргос Лантимос, «Бедные-несчастные»' },
        { type: 'list', text: 'Мартин Скорсезе, «Убийцы цветочной луны»' },
        { type: 'list', text: 'Селин Сон, «Прошлые жизни»' },
        

        
    ]
    },
    {
      id: 2,
      category: 'Фестивали и премии',
      title: 'Завершился первый фестиваль «Маяк». Награды — у Мульменко и Калимулина. Гран-при — дебют!',
      date: '9 октября 2023',
      image: Articles2,
      content: [
        { type: 'paragraph', text: '9 октября в Геленджике завершился фестиваль актуального российского кино «Маяк», учрежденный фондом развития современного кинематографа «Кинопрайм» (поддерживал фильмы «Петровы в гриппе», «Герда», «Экспресс»). Программный директор фестиваля — Стас Тыркин, критик и куратор кинотеатра «Художественный». Вот его фильмы-победители.' },
        { type: 'header', text: '«Королевство», реж. Татьяна Рахманова' },
        { type: 'sub-header', text: 'Лучший дебют и лучшая операторская работа (Алишер Хамидходжаев)' },
        { type: 'paragraph', text: 'Компания подростков из детдома отмечает выпускной в арендованном роскошном особняке. На двухдневную вечеринку ушло все полученное ими пособие. Аренда дома заканчивается, но прекращать веселье никто не намерен: дом превращается в крепость. И даже когда начинается штурм, герои ждут, что все скоро закончится и наступит рассвет.' },
        { type: 'paragraph', text: 'В ролях: Игорь Филиппов, Иван Решетняк, Дарья Каширина, Полина Гурьянова, Алексей Нилов, Даниил Стриганов, Елизавета Лобачёва, Андрей Ильин, Станислав Шадчин.' },
        { type: 'header', text: '«Фрау», реж. Любовь Мульменко' },
        { type: 'sub-header', text: 'Лучший сценарий и лучшая женская роль (Лиза Янковская)' },
        { type: 'paragraph', text: 'Ваня — молодой человек со странностями. Он работает в магазине «Охотник и рыболов», живет в старомодной бабушкиной квартире и называет всех женщин «фрау». Все меняется, когда он знакомится с Кристиной, балериной Пермского театра оперы и балета, и начинает за ней ухаживать на свой оригинальный манер.' },
        { type: 'paragraph', text: 'В ролях: Лиза Янковская, Вадик Королёв (фронтмен группы OQJAV).' },
        { type: 'header', text: '«Год рождения», реж. Михаил Местецкий' },
        { type: 'sub-header', text: 'Лучшая мужская роль — Эльдар Калимулин' },
        { type: 'paragraph', text: 'Мечтатель Филипп настолько увлечен давно распавшейся панк-группой «Яичный свет», андерграундной гордостью его родного Металлогорска, что ведет про нее подкаст и даже устроил у себя в квартире фанатский музей. Все меняется, когда Филипп влюбляется в Марину. И хотя на горизонте маячит взрослая семейная жизнь, он никак не может отказаться от своих панк-фантазий.' },
        { type: 'paragraph', text: 'В ролях: Эльдар Калимулин, Анастасия Талызина, Юра Борисов (отвязный панк-фронтмен!).' },
        { type: 'header', text: '«Чума», реж. Дмитрий Давыдов' },
        { type: 'sub-header', text: 'Лучшая режиссерская работа' },
        { type: 'paragraph', text: 'В якутском селе одна напротив другой живут две неполные семьи, без матерей. Робкого и безответного Ивана постоянно унижает сосед, дерзкий и самоуверенный Тихон. После того как Тихон избивает Ивана на глазах у его сына, подросток разочаровывается в отце и начинает все больше времени проводить с его обидчиком.' },
        { type: 'paragraph', text: 'В ролях: Вячеслав Устинов, Фёдор Сергеев, Перфиль Чупров.' },
        { type: 'header', text: '«Каникулы», реж. Анна Кузнецова' },
        { type: 'sub-header', text: 'Гран-при фестиваля' },
        { type: 'paragraph', text: 'Двенадцать школьников из калужского театрального кружка летят в Сочи на фестиваль. За детьми присматривают строгая завуч Мария Генриховна и молодая учительница-режиссер Таня. Пока две женщины пытаются найти общий язык, юные актеры переживают личные драмы, а репетиции оказываются под угрозой. Вдобавок организаторы конкурса выдвигают неожиданные и абсурдные требования. Режиссер Анна Кузнецова — выпускница Московской школы кино мастерской Хлебникова и Попогребского, который был жюри «Маяка». ' },
        { type: 'paragraph', text: 'В ролях: Дарья Савельева, Полина Кутепова, Ирина Носова, Магога Мачульская, Марфа Иванова, Егор Леонтьев, Андрей Пермяков.' },
    ]
    },
    {
      id: 3,
      category: 'Канны-2023',
      title: '«Индиана Джонс и колесо судьбы» — и без Спилберга. Раньше было лучше',
      date: '29 мая 2023',
      image: Articles3,
      content: [
        { type: 'sub-header', text: 'На стримингах вышел пятый фильм о приключениях археолога с хлыстом. Джеймс Мэнголд вращает назад колесо времени: главный аттракцион этой, верной букве Спилберга блестящей имитации старого доброго Инди — ностальгия. Посмотревший фильм на фестивале в Каннах Зельвенский растроган, но не в восторге. Все-таки Спилберг незаменим.' },
        { type: 'paragraph', text: 'Под занавес Второй мировой войны Индиана Джонс (Харрисон Форд) и его оксфордский друг Бэзил Шоу (Тоби Джонс) похищают в Германии из-под носа нацистского физика Фоллера (Мадс Миккельсен) артефакт (точнее, половину артефакта) под названием Антикитера — сконструированный Архимедом механизм, позволяющий предсказывать положения небесных тел и, возможно, способный на гораздо большее.' },
        { type: 'paragraph', text: 'Спустя четверть века, в 1969 году, Фоллер готовится получить медаль из рук президента США — он только что помог отправить человека на Луну. А Индиана Джонс одиноко живет в Нью-Йорке и выходит на безрадостную пенсию. Но тут появляется дочка покойного уже Бэзила, Елена (Фиби Уоллер-Бридж), и увлекает Джонса на поиски второй половины Антикитеры, за которой, разумеется, продолжают охотиться и нацисты.' },
        { type: 'paragraph', text: 'Логотип Paramount Pictures впервые в истории франшизы не рифмуется с первым кадром: на пятой серии Стивен Спилберг, как известно, передал режиссерские полномочия Джеймсу Мэнголду («Рыцарь дня», «Логан», «Ford против Ferrari»), и тот не посчитал возможным использовать чужую шутку. Тем более что за другими подношениями, поклонами и приветами Спилбергу в последующие 140 минут дело не станет.' },
        { type: 'paragraph', text: 'Вышедшее 15 лет назад «Королевство хрустального черепа» принято высмеивать, но задним числом фильм выглядит очень недурно со всеми своими веселыми дикостями. Зеленые человечки! Спасение от ядерной бомбы в холодильнике! Кейт Бланшетт с русским акцентом и саблей! Поскольку «Королевство» оставило Индиану с наследником и женой, «Колесу» первым делом нужно было что-то с этим делать, и жестокие продюсеры проголосовали за развод и против сына (хотя было бы смешно посмотреть на нынешнего Шайа ЛаБафа в его юношеской роли).' },
        { type: 'paragraph', text: 'Ставку сделали не на эксперименты и хулиганство, а на самый надежный инструмент — ностальгию. От коммунистов снова вернулись к нацистам. Макгаффин опять волшебный, но без летающих тарелок, как бы слегка исторический (Антикитера и вправду существует). Подземные ходы, змеи, самолет, наложенный на карту мира, тоже есть. Погоня по арабскому городу, как в «Искателях»! Юный компаньон (теперь тоже арабский), как в «Храме судьбы»! Реализация папиных обсессий, как в «Крестовом походе»! С антресолей достали даже египетского друга Саллаха, который не имеет вообще никакой функции в сюжете, кроме ностальгической.' },
        { type: 'paragraph', text: 'Чтобы сразу оглушить зрителя приятными воспоминаниями, «Колесо» начинается с бравурного двадцатиминутного пролога, где действует компьютерно омоложенный Форд. Как было видно и по трейлерам, эта технология доведена уже почти до совершенства (легкие проблемы остаются даже не с мимикой, а скорее, с движениями на общих планах). Сладострастное уничтожение нацистов в (а потом и на) мчащемся поезде — это, пожалуй, лучший экшен-фрагмент во всем фильме.' },
        { type: 'paragraph', text: 'Уоллер-Бридж не то чтобы выдающееся кастинговое решение, от нее хочется чего-то менее формального, чем решительная спутница, с которой можно переругиваться (любя). Но если вы вдруг всегда мечтали посмотреть, как именно эта актриса бьет в челюсть и перепрыгивает на ходу между машинами с монтировкой наперевес, ваша мечта исполнилась. Миккельсен, разумеется, и во сне сыграет нацистского ученого, который считает нужным сообщать всем, кто готов слушать, что Гитлер был слабак. Бойд Холбрук почти молча, наоборот, выступает в роли головореза со стрижкой головореза. Антонио Бандерас (почему-то) появляется в неблагодарной эпизодической роли очередного старого друга Индианы. За окном Марокко, Сицилия и другие живописные окрестности Средиземного моря. Визуальных эффектов, причем не всегда убедительных, явный перебор, зато на финальном отрезке они позволяют авторам разгуляться на полную катушку.' },
        { type: 'paragraph', text: 'Мэнголд прекрасно держит ритм: по матрице Спилберга герои безостановочно выпутываются из смертельно опасных ситуаций, хотя теперь еще регулярно вспоминают, что неплохо бы передохнуть (шуток про возраст, конечно, в избытке). Стоит ли говорить, что режиссер все равно не Спилберг? Здесь нет его магической искры, и как бы нас ни старались убедить в обратном, это не совсем настоящий «Индиана Джонс», а скорее, его удачная имитация, и тут ничего не попишешь. Наверное, не обязательно — подсознательно мы все к этому готовы.' },
        { type: 'paragraph', text: 'Та-тара-та, та-та-та. Та-тара-та, та-та-та-та-та. 80-летний Форд, надевая знаменитую шляпу, сегодня играет уже не персонажа, а актера, играющего персонажа, выступает как бы в роли самого себя помоложе. И, наверное, для фильма это нехорошо, но сердце от этого щемит даже сильнее.' },
        
    ]
    },
    {
      id: 4,
      category: 'Канны-2023',
      title: 'Канны, послесловие: как мы запомнили этот фестиваль',
      date: '31 августа 2023',
      image: Articles4,
      content: [
        { type: 'sub-header', text: 'Итоги Канн-2023. Обошлось без сюрпризов: самые интересные фильмы сняли зрелые режиссеры, а фурор произвела разве что красная дорожка.' },
        { type: 'paragraph', text: 'Несмотря на высокий уровень программы, как событие завершившийся Каннский фестиваль трудно признать большой удачей. Сюрпризов — и хороших, и плохих — было немного, жюри проявило себя максимально тусклым образом, и через год вспомнить о фестивале 2023-го будет, кажется, особенно нечего. Фильмом, про который будут говорить, можно назвать разве что взявшую серебро «Зону интересов» Джонатана Глейзера, и даже это не такая культурная бомба, какой был десятилетие назад его «Побудь в моей шкуре».' },
        { type: 'paragraph', text: 'Что у фестивального директора Тьерри Фремо получилось безусловно, так это красная дорожка. ДиКаприо и Де Ниро, Джонни Депп и Харрисон Форд, Квентин Тарантино и Джим Джармуш, Майкл Дуглас и Джейн Фонда, Натали Портман и Скарлетт Йоханссон, Том Хэнкс и Кейт Бланшетт и бог знает сколько еще звезд первой величины — в последние годы Венеция позиционировала себя как голливудский плацдарм в Европе, и сейчас Канны указали, кто есть кто.' },
        { type: 'paragraph', text: 'Афиша основного конкурса тоже выглядела вполне звездной, пусть и несколько пенсионерской: среди 21 участника пять обладателей «Золотой пальмовой ветви». Самое старшее поколение — Марко Беллоккьо, Кен Лоуч, Катрин Брейя, Вим Вендерс — выступило на пределе, видимо, своих нынешних возможностей: побольше у Беллоккьо, поменьше у Брейя, Лоуч как Лоуч. Порадовал Вендерс: он все равно тень себя молодого, но «Прекрасные дни» ближе к винтажному Вендерсу, чем все, может быть, снятое им в этом веке (как минимум в фикшене).' },
        { type: 'paragraph', text: 'Сильнейшее впечатление в самой влиятельной группе 60+ — «Рагу» Чан Ань Хунга, в анонсе (кулинарная мелодрама с Бинош и Мажимелем) не вызывавшее ровно никакого энтузиазма и оказавшееся едва ли не главной жемчужиной программы (французская пресса, что характерно, картину разорвала). «Опавшие листья» Аки Каурисмяки — прекрасный фильм, но ровно такой же, как финн снимал десять, двадцать, тридцать лет назад. Примерно туда же и турок Джейлан, и японец Корээда. «Май, декабрь» Тодда Хейнса очаровательный, чуть более легкомысленный, чем мы привыкли у этого автора, но тоже не то чтобы этапная работа.' },
        { type: 'paragraph', text: 'Находящиеся в расцвете сил Уэс Андерсон и Аличе Рорвахер обрадуют старых поклонников, но вряд ли приобретут много новых. Разочаровали вялый, несмешной Нанни Моретти и дидактическая лирика Катрин Корсини. Равно как и напыщенная, но пустая альтернативная история Карима Айнуза («Подстрекательница»), сонная сатира Джессики Хауснер («Клуб Зеро»), истерический, перегруженный символами мачизм Жан-Стефана Совера («Черные мухи»).' },
        { type: 'paragraph', text: 'После того как в Венеции и Берлине главные призы взяли документальные фильмы, особое внимание было приковано к единственной конкурсной документалке — «Молодости» китайца Ван Бина. Но обстоятельное 212-минутное полотно про жизнь рабочей молодежи на провинциальной фабрике оказалось, несмотря на свое высокое качество, слишком крепким орешком для жюри.' },
        { type: 'paragraph', text: 'Вместе этого оно выбирало между двумя фаворитами, еще в середине фестиваля выделенными в том числе и прессой, — «Зоной интересов» и «Траекторией падения» француженки Жюстин Трие. Яркий, радикальный, но спорный фильм про холокост или мастеровитая панъевропейская судебная драма буржуазных нравов, многим понравившаяся, но едва ли влюбившая в себя хоть кого-то? Очевидно, учитывались, как всегда (но особенно в последние годы), и внехудожественные, анкетные характеристики фильмов и их авторов. Рубен Эстлунд с коллегами решили то, что решили.' },
        { type: 'paragraph', text: 'Параллельные программы прошли, кажется, достаточно тихо. Механический ажиотаж вызвали премьеры Скорсезе и «Индианы Джонса». Критика посвистела модному сериалу «Кумир». Из фестивальных событий можно выделить премьеру короткометражного гей-вестерна Альмодовара. Встреча с Тарантино оказалась сеансом эксплотейшен-боевика конца 1970-х «Гремящий гром», дополненным обсуждением недавней киноведческой книги Квентина. Премьера «последнего фильма» Жан-Люка Годара, 20-минутной нарезки слайдов — мероприятием тоже скорее мемориальным.' },
        { type: 'paragraph', text: 'Скандалов, так необходимых фестивалю, почти не было. Не считая ритуального ропота (впрочем, и радостных визгов) по поводу участия Джонни Деппа в фильме открытия «Жанна Дюбарри», который, кроме этого, мало чем запомнился. На синефильских полях случился еще тихий скандал с испанским классиком Виктором Эрисе, который впервые за несколько десятилетий снял фильм; на премьеру он не приехал, потому что, как выяснилось из его раздраженного открытого письма, рассчитывал на конкурс и стал жертвой интриг Тьерри Фремо.' },
        { type: 'paragraph', text: 'И главное, чего не было, — ощущения безусловной актуальности происходящего на Круазетт, его всемирной значимости. Чувства, что фестиваль прикладывает ухо к нынешней апокалиптической эпохе и что-то слышит. И дело не только в относительной аполитичности Канн: все необходимые слова, в конце концов, были сказаны со сцены. Но, например, единственный фильм в конкурсе, где упоминалась Украина, — комедия Каурисмяки. Понятно, что кино реагирует на новости с некоторым опозданием (два фильма, «Старый дуб» и «Четыре дочери», касались недавних событий в арабском мире). Но также понятно, что в стремительном мире 2020-х это опоздание стоит почтенному европейскому фестивалю дорого и с каждым годом все дороже.' },
       
        
    ]
    },
    {
      id: 5,
      category: 'Венеция-2022',
      title: 'Программа 79-го Венецианского кинофестиваля',
      date: '26 июля 2022',
      image: Articles5,
      content: [
        { type: 'sub-header', text: 'Стала известна программа 79-го Венецианского фестиваля. В этом году смотр откроет фильм Ноа Баумбака «Белый шум». В конкурсную программу Orizzonti Extra («Новые горизонты») вошла полнометражная российско-казахская картина режиссера и сценариста Адильхана Ержанова «Голиаф», в которой сыграли Берик Айтжанов, Данияр Алшинов, Дмитрий Чеботарев и Александра Ревенко. Это фильм совместного производства компаний Short Brothers, Changepoint, All Media (a START Company) и «Форест-Фильм».' },
        { type: 'paragraph', text: 'Вне конкурса покажут сериал «Ковбой из Копенгагена» Николаса Виндинга Рефна о девушке Миу (Анжела Бундалович), которая отправляется знакомиться с криминальным миром Копенгагена, и третий сезон культового «Королевства» Ларса фон Триера с подзаголовком «Исход» (первые два сезона вышли в 1994 и 1997 годах). В конкурсе короткометражного кино состоится премьера фильма «III» Саломе Вильнёв, дочери Дени Вильнёва.' },
        { type: 'header', text: 'Основной конкурс' },
        { type: 'paragraph', text: '«Повелитель муравьев» (Il signore delle formiche) — драма Джанни Амелио («Сицилийцы», «Ключи от дома»). Итальянский поэт, драматург и режиссер Альдо Брайбанти попадает в тюрьму в 1968 году на основании закона, запрещающего гомосексуальные связи. Информатором оказывается отец его партнера, который заставляет сына пройти электрошоковую конверсионную терапию.' },
        { type: 'paragraph', text: '«Кит» (The Whale) — картина Даррена Аронофски, основанная на одноименной пьесе Сэмюэла Д. Хантера. В 2014 году он получил стипендию МакАртура, которую неофициально называют «грантом для гениев». Главную роль в фильме исполнит Брендан Фрейзер. Он играет 270-килограммового мужчину по имени Чарли, который пытается воссоединиться с дочерью Лиз (Хонг Чау). Мужчина бросил свою семью ради любовника. В мировой прокат картину выпустит A24.' },
        { type: 'paragraph', text: '«Белый шум» (White Noise) — фильм Ноа Баумбака с Адамом Драйвером и Гретой Гервиг в главных ролях. Это экранизация одноименного постмодернистского романа Дона ДеЛилло. Джек Глэдни (Драйвер) — профессор, изучающий Адольфа Гитлера в университете, известном как Колледж-на-Холме. Он пытается найти баланс между работой и семьей, справляется со страхом смерти, когда рядом с городом происходит железнодорожная катастрофа, и переживает измену жены Бабетты (Грета Гервиг). За производство отвечает Netflix.' },
        { type: 'paragraph', text: '«Сент-Омер» (Saint Omer) — социальная драма француженки Элис Диоп. 30-летняя писательница Рама присутствует на суде над Лоранс Коли, молодой женщиной, которая обвиняется в убийстве 15-месячной дочери, после того как она оставила ее на пляже во время прилива.' },
        { type: 'paragraph', text: '«Бардо» (Bardo or False Chronicle of a Handful of Truths) — ностальгическая комедия Алехандро Гонсалеса Иньярриту о мексиканском журналисте и документалисте, который возвращается в родную страну и осмысляет свои отношения с ней. Режиссер работает в Мексике впервые за 20 лет после полнометражного дебюта «Сука-любовь».' },
        { type: 'paragraph', text: '«Афина» (Athena) — драматический боевик Ромена Гавраса («Нож гильотины»), сына легендарного режиссера Коста-Гавраса («Наш день придет» и «Мир принадлежит тебе»). Жизнь троих братьев превращается в хаос спустя несколько часов после того, как их младший брат трагически умирает. Кино выйдет на Netflix в сентябре.' },
        { type: 'paragraph', text: '«Личная жизнь» (Love life) — картина Кодзи Фукады, обладателя приза жюри Каннского кинофестиваля в секции «Особый взгляд» за «Фисгармонию» в 2016 году. Таэко (Фумино Кимура) наслаждается счастливой и спокойной жизнью с мужем и сыном, но после трагического инцидента остается одна. Фильм снят по мотивам песни японской джазовой музыкантки Акико Яно «Live Life».' },
        { type: 'paragraph', text: '«Вечная дочь» (The Eternal Daughter) — новый фильм Джоанны Хогг, постановщицы драмы «Сувенир». По сюжету мать (Тильда Суинтон) и дочь возвращаются в семейный дом, где им приходится столкнуться с тайнами прошлого.' },
        { type: 'paragraph', text: '«Аргентина, 1985» (Argentina, 1985) — фильм аргентинца Сантьяго Митре («Паулина») об адвокате и прокуроре, которые осмелились подать в суд на власти Аргентины, обвинив их в преступлениях против граждан. Картина основана на реальных событиях.' },
        { type: 'paragraph', text: '«За стеной» (Beyond the Wall) — иранский фильм авторства Вахида Джалильванда о жизни слепого человека. В 2017 году Джалильванд становился победителем Венеции в секции «Горизонты» за режиссуру фильма «Ни даты, ни подписи».' },
        { type: 'paragraph', text: '«Кьяра» (Chiara) — фильм итальянского режиссера Сузанны Никкьярелли. Ее «Нико, 1988» уже побеждал в секции «Горизонты» в 2017 году. Картина посвящена Кларе Ассизской, итальянской святой, которая после проповеди святого Франциска оставила свою богатую семью, чтобы стать монахиней.' },
        { type: 'paragraph', text: '«Блондинка» (Blonde) — нетфликсовский байопик Мэрилин Монро от Эндрю Доминика. Роль дивы играет Ана де Армас. Сам режиссер описал «Блондинку» как «фильм для нелюбимых детей со всего мира, как будто у „Гражданина Кейна“ и „Бешеного быка“ родилась дочь». Сюжет посвящен тому, как детская травма влияет на уже повзрослевшего человека. «Это своего рода фильм о бессознательном. Трагедия о нежеланном ребенке, который затем становится самой желанной женщиной в мире». Фильм снят по одноименной книге Джойс Кэрол Оутс.' },
        { type: 'paragraph', text: '«Моника» (Monica) — драма Андреа Паллаоро («Ханна») про трансгендерную женщину, которая ухаживает за умирающей матерью. Фильм описывают как деликатную историю о расколотой семье, в которой исследуются темы брошенности, старения, принятия и искупления.' },
        { type: 'paragraph', text: '«Без медведей» (No Bears) — романтическая картина иранского режиссера Джафара Панахи, который был приговорен к шести годам тюремного заключения. В фильме параллельно развиваются две любовные истории, в которых партнерам мешают общественные стереотипы, суеверия и само государство.' },
        { type: 'paragraph', text: '«Тар» (Tár) — фильм Тодда Филда («Как малые дети», «В спальне»). Кейт Бланшетт играет вымышленного выдающегося дирижера и композитора Лидию Тар, которая становится первой в истории женщиной-дирижером Берлинского оркестра. Музыкальным сопровождением картины занималась Хильдур Гуднадоуттир, работавшая ранее над «Джокером» и сериалом «Чернобыль».' },
        { type: 'paragraph', text: '«Вся красота и кровопролитие» (All the Beauty and the Bloodshed) — фильм Лоры Пойтрас, ​​чей док «Citizenfour: Правда Сноудена» в 2014 году взял «Оскар» как лучший документальный фильм. ' },
        { type: 'paragraph', text: 'L’immensita — итальянская драма Эмануэле Криалезе («Дыхание», «Траст»). Пенелопе Крус досталась роль жительницы Рима и матери семейства, Клары. События картины разворачиваются в итальянской столице в 1970-е годы. «Мир, зависший между строящимися районами и еще черно-белыми телешоу, новыми социальными достижениями и семейными ценностями», — говорит о фильме Криалезе в интервью Variety.' },
        { type: 'paragraph', text: '«Целиком и полностью» (Bones and All) — проект Луки Гуаданьино, основанный на одноименном романе Камиллы ДеАнджелис. Главные роли играют Тэйлор Рассел и Тимоти Шаламе. В центре сюжета — девушка Марен, которая отправляется в поездку по стране, чтобы найти своего отца. Она его никогда не встречала, но с его помощью рассчитывает понять, почему ей постоянно хочется убивать и есть любящих ее людей.' },
        { type: 'paragraph', text: '«Пара» (A Couple) — новый фильм режиссера Фредерика Вайсмана («Экслибрис: Нью-Йоркская публичная библиотека»), обладателя премии «Оскар» за заслуги перед кинематографом. Картина совместного производства Франции и США. Это художественный фильм о непростых отношениях русского писателя Льва Толстого и его жены Софьи Толстой.' },
        { type: 'paragraph', text: '«Наши связи» (Our Ties) — фильм французского актера и режиссера Рошди Зема (серебряная премия Каннского кинофестиваля за лучшую мужскую роль в фильме «Патриоты»). Фильм описывают как динамическую драму о семье.' },
        { type: 'paragraph', text: '«Банши Инишира» (The Banshees of Inisherin) — экранизация ирландским режиссером Мартином МакДоной («Залечь на дно в Брюгге») собственной одноименной пьесы. В главных ролях снялись Колин Фаррелл, Брендан Глисон и Барри Кеоган. Драма о кризисе в отношениях двух старых друзей.' },
        { type: 'paragraph', text: '«Дети других людей» (Other People’s Children) — драма француженки Ребекки Злотовски о бездетной женщине, которая устанавливает духовную связь с маленькой дочерью своего парня.' },
        { type: 'paragraph', text: '«Сын» (The Son) — драма Флориана Зеллера, основанная на его же пьесе. В центре сюжета — Питер (Хью Джекман), который переживает напряженный период в браке. Все осложняется, когда в его жизни появляется бывшая жена с сыном-подростком с трудным характером. Помимо Джекмана, в фильме снимаются Лора Дерн, Ванесса Кирби, Зен МакГрат и Энтони Хопкинс. Фильм выпустит в российский прокат кинокомпания «Экспонента».' },
        
        
    ]
    },
    {
      id: 6,
      category: 'Канны-2022',
      title: 'Канны объявили программу. В основной конкурс снова вошел фильм Кирилла Серебренникова',
      date: '16 мая 2022',
      image: Articles6,
      content: [
        { type: 'sub-header', text: '17 — 28 мая пройдет Каннский фестиваль. Российский кинокритик Антон Долин и украинский кинокритик Наталья Серебрякова вошли в жюри прессы Screen. Все критики этого жюри будут проставлять оценки от 0 до 4 звезд фильмам основного конкурса.' },
        { type: 'paragraph', text: 'Вне конкурса покажут сериал «Ковбой из Копенгагена» Николаса Виндинга Рефна о девушке Миу (Анжела Бундалович), которая отправляется знакомиться с криминальным миром Копенгагена, и третий сезон культового «Королевства» Ларса фон Триера с подзаголовком «Исход» (первые два сезона вышли в 1994 и 1997 годах). В конкурсе короткометражного кино состоится премьера фильма «III» Саломе Вильнёв, дочери Дени Вильнёва.' },
        { type: 'paragraph', text: 'С 17 по 25 мая в Каннах также будет проходить кинорынок, на котором Кирилл Серебренников представит фрагменты из фильма об Эдуарде Лимонове. Картина под названием «Лимонов, баллада об Эдичке» будет снята на английском языке, а в прокат она должна выйти в 2023 году. На главную роль режиссер выбрал английского актера Бена Уишоу — звезду «Парфюмера», «Фарго», последних картин о Джеймсе Бонде и сериала «Будет больно». Сценарий для фильма подготовили режиссер «Холодной войны» Павел Павликовский и Бен Хопкинс («Марионетка»), основан фильм на книге «Лимонов» французского писателя Эммануэля Каррера.' },
        { type: 'paragraph', text: 'Президентом жюри в этом году стал актер Венсан Линдон («Титан»). Фильм открытия — комедия о зомби «Окончательный монтаж» Мишеля Хазанавичуса. Рассказываем про остальных участников смотра.' },
        { type: 'header', text: 'Конкурсная программа' },
        { type: 'paragraph', text: '«Тори и Локита». Братья Дарденн дважды получали «Золотую пальмовую ветвь» — за фильмы «Розетта» в 1999 году и «Дитя» в 2005-м. Их новый фильм посвящен истории о паре молодых беженцев из Африки, которые отправились на поиски лучшей жизни в Бельгию. В фильме снимаются бельгийский актер Марк Зинга («007: СПЕКТР») и ранее сотрудничавшие с Дарденнами Клер Бодсон («Молодой Ахмед») и Батисте Сорнен («Мальчик с велосипедом», «Два дня, одна ночь»).' },
        { type: 'paragraph', text: '«Мальчик с небес» — триллер шведского режиссера и продюсера Тарика Салеха («Наемник», «Мир дикого запада»). В первый же день после летних каникул великий имам падает и умирает на глазах у своих студентов в престижном университете Каира. Это знаменует начало безжалостной битвы за его место.' },
        { type: 'paragraph', text: '«Посредник» — социальная драма Хирокадзу Корээды, снятая в Южной Корее. Это история нескольких людей, которые связаны с беби-боксом — местом, где родители могут оставлять нежеланных младенцев. В главных ролях: Сон Кан-хо («Паразиты»), Пэ Ду-на («Сочувствие господину Месть»), Кан Дон-вон («Поезд в Пусан 2») и Ли Джи-ын («Алое сердце»).' },
        { type: 'paragraph', text: '«Время Армагеддона» — художественный фильм Джеймса Грэя («К звездам»). Действие развернется в начале 1980-х, когда Америка выбирала Рейгана. В основе сюжета фильма — личные воспоминания режиссера о времени, когда он учился в той же самой школе, что и Дональд Трамп. Джессика Честейн играет помощницу прокурора США, будущую федеральную судью и сестру Дональда Трампа по имени Мэриэнн Трамп-Берри. Она выступала с речью перед учениками школы, среди которых был 12-летний Грэй. Пока известно, что это будет история, посвященная природе дружбы, а одним из центральных персонажей станет директор школы. В главных ролях: Энн Хэтэуэй, Джереми Стронг, Энтони Хопкинс.' },
        { type: 'paragraph', text: '«Жена Чайковского» — биографическая драма Кирилла Серебренникова про Антонину Милюкову. Ее сыграла Алена Михайлова, а Чайковского — Один Байрон. В фильме также снялись Oxxxymiron и Филипп Авдеев. Серебренников уже в третий раз участвует в основном конкурсе Каннского кинофестиваля. Его картина «Лето» была представлена в 2018 году, а «Петровы в гриппе» — в 2021-м.' },
        { type: 'paragraph', text: '«Треугольник печали» — первый англоязычный проект шведского режиссера Рубена Эстлунда. Его предыдущие фильмы «Форс-мажор» и «Квадрат» были хитами Каннского фестиваля (последняя картина принесла режиссеру «Золотую пальмовую ветвь», номинацию на «Оскар» и приз Европейской киноакадемии за лучший фильм). «Треугольник печали» — фильм о крушении яхты посреди океана. Его герои, которые оказываются на необитаемом острове, — пара супермоделей, несколько миллиардеров, капитан-марксист (Вуди Харрельсон) и клинерка, единственная из выживших, кто умеет готовить. российский кинопрокат фильм выпустит кинокомпания A-One. Дата релиза будет объявлена дополнительно.' },
        { type: 'paragraph', text: '«Преступления будущего». Дэвид Кроненберг возвращается к боди-хоррору. В мире недалекого будущего жизнь человечества изменил «синдром ускоренной эволюции». Артист Сол (Вигго Мортенсен) отращивает себе новые органы для развлечения публики, ему помогает жена Каприз (Леа Сейду). У режиссера уже есть фильм с аналогичным названием (так назывался второй проект канадца), но новая картина не является его ремейком. Кроме Мортенсена и Сейду, в фильме снялась Кристен Стюарт. В российский кинопрокат фильм выпустит кинокомпания Capella. Дата релиза будет объявлена дополнительно.' },
        { type: 'paragraph', text: '«Звезды в полдень». Действие фильма Клер Дени («Высшее общество») происходит в 1984 году в Никарагуа времен правления сандинистов. Его главные герои — английский бизнесмен и американская журналистка, у которых завязывается роман. По политическим причинам им обоим нужно бежать из страны, но их преследует агент ЦРУ, который пытается заставить женщину сдать возлюбленного. История основана на одноименном романе Дениса Джонсона 1986 года. Изначально компанию Маргарет Куэлли, которая играет главную роль, должен был составить Роберт Паттинсон, но он выбился из графика съемок, поэтому его место занял Джо Элвин («Фаворитка»).' },
        { type: 'paragraph', text: '«Миндальное дерево» — драмеди Валерии Бруни-Тедески, номинантки на «Золотую пальмовую ветвь» за фильм «Замок в Италии». Конец 1980-х. Двадцатилетние Стайл, Виктор, Адель и Этьен поступают в актерскую школу Патриса Шеро и Пьера Романса. Вместе герои переживут поворотный момент в своей жизни, а также первую трагедию. Шеро сыграл Луи Гаррель.' },
        { type: 'paragraph', text: '«Решение уйти» — романтический триллер Пак Чхан-ука («Олдбой») о детективе, который влюбляется в главную подозреваемую — жену убитого. Главные роли исполняют Пак Хэ-иль («Воспоминания об убийстве»), Тан Вэй («Долгий день уходит в ночь») и Ли Джон-хён («Поезд в Пусан 2: Полуостров»). Сценарий совместно с Чхан-уком написала Чон Со-гён, с которой постановщик сотрудничал на проектах «Сочувствие госпоже Месть», «Жажда» и «Служанка». Права на российский прокат уже куплены. В российский кинопрокат фильм выпустит кинокомпания Arna Media. Дата релиза будет объявлена дополнительно.' },
        { type: 'paragraph', text: 'Close — драма Лукаса Донта. В 2018-м он показывал на фестивале «Девочку», за которую получил три награды — за режиссерский дебют, приз ФИПРЕССИ и «Квир-пальму» за освещение ЛГБТ-темы в кино. Его новый фильм посвящен 13-летним мальчикам Лео и Реми, которые дружили, но поссорились. Помирить друзей пытается Софи, мать Реми.' },
        { type: 'paragraph', text: 'R.M.N — драма румынского режиссера Кристиана Мунджиу. Он получал призы в Каннах за «Выпускной» (лучшая режиссура), «За холмами» (лучший сценарий), «4 месяца, 3 недели и 2 дня» («Золотая пальмовая ветвь»). Сюжет его нового фильма неизвестен.' },
        { type: 'paragraph', text: '«Священный паук» — криминальная драма Али Аббаси (Гран-при секции «Особый взгляд» в 2018-м за фильм «На границе миров»). Семьянин Саид сам ставит перед собой религиозную миссию — очистить иранский город Мешхед от проституток. Но после нескольких убийств он обнаруживает, что обществу до его крестового похода нет никакого дела.' },
        { type: 'paragraph', text: '«Братья Лейлы» — картина иранского режиссера Саида Рустаи. Его работы в основном посвящены вопросам социальной несправедливости, а также изображению женщин в иранском обществе. Его последний фильм «Зависимые» был номинирован в этом году на премию «Сезар» как лучший фильм на иностранном языке и участвовал в секции «Горизонты» на Венецианском кинофестивале в 2019 году. Подробностей нового фильма пока нет.' },
        { type: 'paragraph', text: 'Showing Up  — четвертая совместная работа режиссера Келли Райхардт («Первая корова») и Мишель Уильямс. Актриса играет скульптора Лиззи, которая вдохновляется отношениями со своей семьей и друзьями для создания будущей выставки.' },
        { type: 'paragraph', text: '«Брат и сестра» — драма постоянного участника Каннского кинофестиваля Арно Деплешена («Боже мой!», «Эстер Кан», «Рождественская сказка»). Алиса (Марион Котийяр) и Луи (Мельвиль Пупо) — брат и сестра, которые не общались 20 лет. Они мирятся, после того как их родители попадают в автокатастрофу.' },
        { type: 'paragraph', text: '«Иа» — фильм польского режиссера Ежи Сколимовского (получал призы в Каннах за «Вопль» и «Лунный свет»). Его новая работа — ремейк французского фильма «Наудачу, Бальтазар» Робера Брессона. В основу лег античный роман Апулея «Золотой осел». В фильме жизнь ослика Бальтазара начинается в польском цирке, а заканчивается на итальянской скотобойне.' },
        { type: 'paragraph', text: '«Ностальгия» — драма итальянского режиссера Марио Мартоне (в последний раз он принимал участие в смотре в 1995 году с фильмом «Любовь утомляет»). В центре сюжета — мужчина по имени Феличе (Пьерфранческо Фавино). Он возвращается в родной район Неаполя после сорока лет отсутствия, чтобы вспомнить молодость.' },
        { type: 'paragraph', text: '«Восемь гор» — фильм Шарлотты Вандермерс («Разомкнутый круг») и Феликса ван Грунингена («Красивый мальчик»). Картина основана на одноименном романе Паоло Коньетти. Бруно живет в горной деревне, а Пьетро — в большом городе, но ему очень трудно сходиться с людьми. Когда они друг друга встретят, это изменит жизни обоих.' },
        { type: 'paragraph', text: '«Мучения на островах» — фильм каталонского режиссера Альберта Серры (его картина «Свобода» взяла приз в секции «Особый взгляд» на Каннском кинофестивале в 2019 году). Писательница в творческом кризисе возвращается к себе на родину во Французскую Полинезию. Новых идей для книги у нее нет, поэтому женщина соглашается на работу синхронного переводчика в местное посольство. Там она столкнется с цинизмом международной политики и узнает о скрытой угрозе новых ядерных испытаний, которые хочет провести французское правительство.' },
        { type: 'paragraph', text: '«Младший брат» — драма француженки Леонор Серай (награда за режиссерский дебют на Каннском кинофестивале в 2017 году за фильм «Молодая женщина»). Это история о женщине Розе, ивуарийке по происхождению, которая в конце 1980-х годов переезжает в парижский пригород с двумя сыновьями.' },
        { type: 'paragraph', text: '' },

        
    ]
    },
    {
      id: 7,
      category: 'Фестивали и премии',
      title: 'Объявлены победители «Золотой малины». В лидерах — мюзикл о принцессе Диане и фильм с Леброном Джеймсом',
      date: '26 марта 2022',
      image: Articles7,
      content: [
        { type: 'sub-header', text: 'В США минувшей ночью подвели итоги антипремии «Золотая малина». Победителем в этом году стала картина Кристофера Эшли «Диана: Мюзикл» — она одержала верх в номинации «Худший фильм».' },
        { type: 'paragraph', text: 'Снятая Эшли лента — это вышедший до премьеры на Бродвее мюзикл о трагической судьбе принцессы Дианы, поставленный на стихи Дэвида Брайана и Джо Ди Пьетро (также получили в этом году «Золотую малину» за худший сценарий). В основе сценария лежала также книга Ди Пьетро о жизни Принцессы Уэльской.' },
        { type: 'paragraph', text: 'Всего «Диана» победила в пяти номинациях, а мультфильм с Леброном Джеймсом «Космический джем: Новое поколение» — в трех.' },
        { type: 'sub-header', text: 'Полный список победителей:' },
        { type: 'list', text: 'Худший актер — Леброн Джеймс («Космический джем: Новое поколение»)' },
        { type: 'list', text: 'Худшая актриса — Жанна де Ваал («Диана: Мюзикл»)' },
        { type: 'list', text: 'Худший актер второго плана — Джаред Лето («Дом Gucci»)' },
        { type: 'list', text: 'Худшая актриса второго плана — Джуди Кэй («Диана: Мюзикл»)' },
        { type: 'list', text: 'Худшая экранная пара — Леброн Джеймс и любой персонаж из мультфильма Warner («Космический джем: Новое поколение»)' },
        { type: 'list', text: 'Худший ремейк или сиквел — «Космический джем: Новое поколение»' },
        { type: 'list', text: 'Худший режиссер — Кристофер Эшли («Диана: Мюзикл»)' },
        { type: 'list', text: 'Худший сценарий — «Диана: Мюзикл»' },
        { type: 'paragraph', text: '«Золотую малину» традиционно вручают накануне «Оскара», который в этом году пройдет в ночь на 28 марта.' },

        
    ]
    },
    {
      id: 8,
      category: 'Венеция-2021',
      title: 'Венеция-2021: итоги. Чем отличились Пенелопа Крус, Мэгги Джилленхол и Джейн Кэмпион',
      date: '13 сентября 2021',
      image: Articles8,
      content: [
        { type: 'header', text: 'Распределение наград жюри в этом году стало сюрпризом для критиков. Подробнее о неожиданных победителях 78-го Венецианского кинофестиваля рассказывает Инна Денисова.' },
        { type: 'paragraph', text: 'Венецианский кинофестиваль вот уже второй год проводится в условиях в пандемии: обязательное ношение масок, рассадка через одного в кинозалах и сдача ПЦР каждые два дня для непривитых (при входе в зал тест просили показывать вместе с паспортом) — все эти карантинные меры сохранились с прошлого года, когда сам факт проведения массового мероприятия казался опасным экспериментом. Однако в этом году в Венецию приехало уже в два раза больше людей, и это обрушило систему электронных билетов, так удачно запущенную в прошлом году. Залы не вмещали всех аккредитованных, в первые дни фестиваля ровно половина прессы не могла взять билеты, которые заканчивались через 10 минут, из-за чего у журналистов случилась паника, пресс-центр забрасывали письмами, а в соцсетях появлялись гневные посты.' },
        { type: 'paragraph', text: 'К середине фестиваля ситуация улучшилась, и технический отдел Мостры пообещал учесть уроки этого года — похоже, что система электронных билетов теперь с нами надолго, если не навсегда.' },
        { type: 'sub-header', text: 'Основные призеры' },
        { type: 'paragraph', text: 'Конкурсную программу судило жюри под председательством Пон Джун-хо («Паразиты»). Его решение отдать «Золотого льва» фильму «Событие» Одри Диван (это ее второй фильм) вызвало удивление. Фильм хотя и активно хвалили за актуальную тему («Событие» посвящено попыткам сделать аборт в стране, где эта операция вне закона), но не прочили в победители. Подробнее о «Событии», его особенностях и контексте награждения читайте тут.' },
        { type: 'paragraph', text: 'Гендерный баланс призов тут же уравновесил Гран-при «Руке бога» Паоло Соррентино, одному из немногих очевидных фаворитов критики. Фильм снят в типичной размашистой манере этого режиссера, но его главный герой — уже сам Соррентино. Подробнее о том, что происходит в этой автобиографической картине, действительно едва ли не лучшей в фильмографии режиссера, мы писали тут.' },
        { type: 'paragraph', text: '«Серебряный лев» за лучшую режиссуру — у Джейн Кэмпион, снявшей ревизионистский вестерн «Власть пса». Подробнее об этой истории мужских страстей, которые разгораются в пыльных горных ландшафтах Монтаны рубежа XIX и XX веков мы писали здесь.' },
        { type: 'paragraph', text: 'Спецприз жюри — у вуайеристской «Дыры» Микеланджело Фраммартино. «Дыра» радикально отличалась от остальных участников конкурса своим языком. При этом она тоже вполне типичный (для Фраммартино) фильм: никаких диалогов, эстетика мокьюментари; главный герой — дыра в земле (в прошлых фильмах режиссера протагонистами становились вековое дерево и вымирающая деревенька). Начальный титр сообщит нам историю: в 1961-м спелеологи обнаружили в пьемонтских горах расщелину, начали спускаться туда, и в конце концов выяснилось, что эта дыра вторая по глубине в мире. Фильм реконструирует их экспедицию: в дыру летят подожженные газеты, красиво мерцая, вскоре там даже найдутся фотографии Софи Лорен и Кеннеди, а вокруг дыры живет дремотной, абсолютно оторванной от мира жизнью крошечная деревенька. Жизни и судьбы пастухов и спелеологов зарифмованы в лирическую поэму о жизни и смерти.' },
        { type: 'paragraph', text: 'Приз за лучший сценарий — у Мэгги Джилленхол за фильм «Пропавшая дочь», о котором можно прочитать здесь. Получая награду, Мэгги сказала со сцены, что с Италией ее связывают особенные отношения: здесь она выходила замуж, здесь были зачаты оба ее ребенка, она сняла фильм по итальянскому роману и вот теперь получает здесь приз.' },
        { type: 'paragraph', text: '«Кубок Вольпи» за лучшую женскую роль получила Пенелопа Крус, сыгравшая в «Параллельных матерях» Педро Альмодовара. На пресс-конференции после показа режиссер подчеркнул, что это самая сложная роль за всю карьеру Крус — что ж, жюри было согласно. Мы — тоже, но сложность эту надо понимать не как технические трудности, а как многогранность персонажа: в фигуре сыгранной Крус матери-одиночки сошлись и частная история, и национальная, и семейная (героиня Крус воспроизводит жизненный сценарий вслед за своей матерью и бабушкой). Подробнее о «Матерях» — остросюжетной и страстной истории женской дружбы и близости — мы писали тут.' },
        { type: 'paragraph', text: 'Лучший актер — филиппинец Джон Арсилья, сыгравший журналиста-расследователя в нуарном мини-сериале (его тут показывали как фильм) Эрика Матти «На работе: Пропавшая 8». Это второй сезон шоу, снятого для HBO Asia и основанного на реальных событиях — коррупции и серии политических убийств и похищений в филиппинской провинции.' },
        { type: 'paragraph', text: 'Почти все эти награды, кроме разве что приза Крус и Соррентино (показательно, что из всего изобилия итальянского кино в конкурсе — около половины! — жюри, не мучаясь, выбрало самого известного), стали сюрпризом для критиков. Получивший самые высокие оценки прессы (и российской, и иностранной), «Холодный расчет» Пола Шредера остался без награды, так же как и блестящая экранизация «Утраченных иллюзий» Бальзака, сделанная Ксавье Джанноли. Эта вечно актуальная история журналиста-оппортуниста, предающего друзей и коллег, меняющего политические убеждения как носки, несмотря на декорации XIX века, смотрится крайне актуально в наше время.' },
        
    ]
    },
    {
      id: 9,
      category: 'Канны-2021',
      title: 'Во Франции объявили победителей 74-го Каннского фестиваля',
      date: '17 июля 2021',
      image: Articles9,
      content: [
        { type: 'sub-header', text: '17 июля жюри Каннского фестиваля, председателем которого в этом году назначен режиссер Спайк Ли, объявило лауреатов. «Золотую пальмовую ветвь» присудили триллеру «Титан» Джулии Дюкорно.' },
        { type: 'paragraph', text: 'Гран-при в программе «Особый взгляд» получила российская драма «Разжимая кулаки» Киры Коваленко, ученицы Александра Сокурова. После победы британский стриминговый сервис Mubi купил права на показ фильма на территории Северной Америки, Великобритании, Ирландии, Латинской Америки и Индии. А дистрибьютор Wild Bunch International заключил сделки о показах «Разжимая кулаки» во Франции, Бельгии, Нидерландах, Люксембурге, Греции, Италии, Израиле, Турции и ряде других стран.' },
        { type: 'paragraph', text: 'Наряду со Спайком Ли в жюри вошли Мэгги Джилленхол, Джессика Хауснер, Клебер Мендонса Фильо, Мати Диоп, Милен Фармер, Сон Кан-хо, Мелани Лоран и Тахар Рахим. Призы они распределили следующим образом (всех участников смотра можно посмотреть здесь).' },
        { type: 'sub-header', text: '«Золотая пальмовая ветвь»' },
        { type: 'paragraph', text: '«Титан», режиссер Джулия Дюкорно;' },
        { type: 'sub-header', text: 'Гран-при' },
        { type: 'paragraph', text: '«Герой», режиссер Асгар Фархади;' },
        { type: 'paragraph', text: '«Купе номер шесть», режиссер Юхо Куосманен;' },
        { type: 'sub-header', text: 'Приз жюри' },
        { type: 'paragraph', text: '«Память», режиссер Апичатпонг Вирасетакул;' },
        { type: 'paragraph', text: '«Колено Ахеда», режиссер Надав Лапид;' },
        { type: 'sub-header', text: 'Лучший режиссер' },
        { type: 'paragraph', text: 'Леос Каракс («Аннетт»);' },
        { type: 'sub-header', text: 'Лучшая мужская роль' },
        { type: 'paragraph', text: 'Калеб Лэндри Джонс («Нитрам»);' },
        { type: 'sub-header', text: 'Лучшая женская роль' },
        { type: 'paragraph', text: 'Ренате Реинсве («Худший человек на свете»);' },
        { type: 'sub-header', text: 'Лучший сценарий' },
        { type: 'paragraph', text: '«Сядь за руль моей машины», Рюсукэ Хамагути и Такамаса Оэ;' },
        { type: 'sub-header', text: '«Золотая камера» (лучший дебют)' },
        { type: 'paragraph', text: '«Мурина», режиссер Антонета Кусиянович.' },

        
    ]
    },
    {
      id: 10,
      category: 'Фестивали и премии',
      title: 'Netflix прекращает сотрудничество с организаторами «Золотого глобуса». Их обвиняют в отсутствии инклюзивности',
      date: '8 мая 2021',
      image: Articles10,
      content: [
        { type: 'sub-header', text: 'Глава Netflix Тед Сарандос объявил, что стриминг разрывает отношения с Голливудской ассоциацией иностранной прессы (HFPA) до тех пор, пока в ней не произойдут значительные изменения.' },
        { type: 'paragraph', text: 'В Ассоциацию входит около 90 журналистов, которые ежегодно присуждают премию «Золотой глобус». HFPA неоднократно обвиняли в коррупции и отсутствии расового разнообразия среди ее членов. В феврале Los Angeles Times выпустила расследование, где рассказала о слишком крупных для некоммерческой организации выплатах журналистам Ассоциации. Издание также отметило, что HFPA не всегда выбирает номинантов на «Золотой глобус» по их кинематографической ценности. Например, сериал Netflix «Эмили в Париже» получил две номинации, несмотря на негативные отзывы критиков. За год до этого Paramount отвезла членов HFPA на съемки сериала во Франции, где разместила их в пятизвездочных отелях.' },
        { type: 'paragraph', text: '6 мая в HFPA проголосовали за реорганизацию, 75 членов из 86 поддержали идею. Однако никаких конкретных изменений не было предложено. «Как и многие в индустрии, мы ждали сегодняшнего объявления в надежде, что вы признаете масштаб проблем, стоящих перед HFPA, и предоставите четкий план действий. Сегодняшнее голосование — важный первый шаг, — отметил Сарандос в письме руководству HFPA. — Тем не менее мы не считаем, что предлагаемые меры, особенно касающиеся состава журналистов, решат проблемы разнообразия в Ассоциации. Поэтому мы прекращаем любое сотрудничество с вашей организацией до тех пор, пока не будут осуществлены более значительные изменения».' },
        { type: 'paragraph', text: 'Помимо этого глава Netflix попросил HFPA установить правила, касающиеся мероприятий Ассоциации. Проблема заключается в том, что Ассоциация просила разных кинематографистов, в том числе и создательницу «Бриджертонов» Шонду Раймс, участвовать в своих пресс-конференциях. Однако события с участием темнокожих кинематографистов плохо продвигаются организаторами, из-за чего их мало посещают.' },
        { type: 'paragraph', text: 'Сарандос также подчеркнул, что Netflix не может игнорировать некомпетентность руководства Ассоциации, которое не способно оперативно провести внутренние реформы. В этом году Netflix стал лидером среди кинокомпаний по количеству номинаций на «Золотой глобус». Из 42 номинаций проекты сервиса получили 10 наград, среди прочих члены Ассоциации отметили «Корону», «Ход королевы» и «Ма Рейни: Мать блюза».' },
        { type: 'paragraph', text: 'Вскоре после заявления Netflix сама HFPA опубликовала письмо, где пообещала прозрачность работы над будущими изменениями. Ассоциация уточнила, что наняла консультанта по разнообразию и займется образованием своих членов в этой сфере. За полтора года HFPA также планирует на 50% увеличить количество своих членов. Более того, не только новые, но и действующие участники Ассоциации должны будут отвечать обновленным критериям отбора.' },
    ]
    },
    {
      id: 11,
      category: 'Фестивали и премии',
      title: 'Объявлены победители III церемонии фестиваля Crystal Screen',
      date: '19 марта 2020',
      autor: 'Марина Комина',
      image: Articles11,
      content: [
        { type: 'sub-header', text: '15 марта 2020 года в Центральном доме кино в Москве состоялась третья церемония фестиваля Crystal Screen, на которой были отмечены лучшие работы в области кино и телевидения. В этом году фестиваль собрал более 450 заявок от выдающихся деятелей кино и телевидения. Это знаковое событие собрало под одной крышей выдающихся представителей индустрии, которые продемонстрировали высокое качество и креативность своих проектов.' },
        { type: 'header', text: 'Победители в кинономинациях' },
        { type: 'paragraph', text: 'В категории "Лучший фильм" награду получила Наталья Сергиева за свою картину «Дыхание вечности», которая впечатлила жюри своей эмоциональной глубиной и художественной ценностью. В той же категории "Лучшая режиссура" Наталья также удостоилась признания за мастерское создание целостного произведения. В номинации "Лучший режиссер короткометражного фильма" победила Алиса Виноградова с фильмом «Фингерфуд», которая была отмечена за оригинальность и эмоциональную насыщенность своей работы.' },
        { type: 'paragraph', text: 'Андрей Захаров завоевал звание "Лучший актер" за роль в фильме «Волчье сердце», а Ксения Иванова была признана "Лучшей актрисой" за свою игру в фильме «Пепел». Награду за "Лучший сценарий" получила Ольга Михайлова за фильм «Ледяной сон».' },
        { type: 'paragraph', text: 'Победителем в категории "Лучшая операторская работа" стал Владимир Кравцов с фильмом «Дыхание вечности», а "Лучший монтаж" был признан за работу Марины Григорьевой в фильме «Волчье сердце». Илья Орлов был отмечен за "Лучшие визуальные эффекты" в фильме «Ледяной сон», а "Лучшая музыка для фильма" досталась Сергею Иванову за работу над фильмом «Пепел». Наконец, награду за "Лучшего продюсера" получил Роман Писарев за фильм «Дом», Жюри отметило его выдающиеся организаторские способности.' },
        { type: 'header', text: 'Победители в телевизионных номинациях' },
        { type: 'paragraph', text: 'В телевизионных номинациях лучшим признали программу Владимира Познера «Познер», которая была отмечена за высокий уровень интервью и анализа. Дмитрий Светозаров стал обладателем награды за "Лучший телевизионный сериал" с проектом «Шакал», который был оценен за уникальное сочетание детективной и драматической составляющей.' },
        { type: 'paragraph', text: 'Александр Моисеев был признан "Лучшим режиссером телевизионного проекта" за сериал «Каменская», в то время как лучший сценарий получил Андрей Смирнов за «Великую». Михаил Мукасей был удостоен награды за "Лучшую операторскую работу для телевидения" в сериале «Каменская», а Ирина Лебедева отметилась в категории "Лучший монтаж для телевидения" за программу «Познер». Валентин Черкасов получил признание за "Лучшие визуальные эффекты для телевидения" в сериале «Шакал», а Алексей Рыбников стал победителем за "Лучшую музыку для телевизионного проекта" с саундтреком к «Великой». Награду за "Лучшего продюсера телевизионного проекта" получил Сурен Джулакян за «Битву экстрасенсов» на телеканале СТБ Украина.' },
        { type: 'header', text: 'Гран-при “За вклад в профессию”' },
        { type: 'paragraph', text: 'Особую награду Гран-при “За вклад в профессию” получил Александр Орлов, знаменитый режиссер, чьи фильмы, такие как «Тень прошлого» и «В поисках света», оставили неизгладимый след в российском кинематографе. Орлов славится своим оригинальным стилем и мастерством создания картин, которые глубоко резонируют с эмоциями зрителей. Его вклад в киноискусство не ограничивается лишь успешными проектами; он активно наставляет молодых режиссеров, что придает его деятельности особую значимость в индустрии.' },
        { type: 'header', text: 'Жюри фестиваля' },
        { type: 'paragraph', text: 'Состав жюри фестиваля Crystal Screen в этом году включал признанных экспертов и мастеров киноиндустрии. Председателями стали Марина Воронцова, Игорь Николаев и Алексей Лебедев. Каждый из них имеет богатый опыт и авторитет в своей области, что гарантирует высокий уровень отбора работ.' },
        { type: 'paragraph', text: 'В жюри также вошли Наталья Иванова, актриса с более чем 30-летним опытом, Максим Орлов, сценарист и лауреат премии «ТЭФИ», и Виктор Петров, художник-постановщик, который получил признание на международных фестивалях. Каждый член жюри отвечал за тщательный отбор номинантов и оценку их работ по ряду критериев, включая оригинальность, художественную ценность и техническое мастерство.' },
        { type: 'paragraph', text: 'Марина Воронцова отметила: «Работа в жюри — это огромная ответственность, и мы стремились к тому, чтобы отдать должное всем талантливым проектам». Игорь Николаев добавил: «Каждая из представленных работ оставила своеобразный след в сердце и уме. Это вдохновляет на дальнейшую работу в индустрии».' },
        { type: 'header', text: 'Комментарии победителей' },
        { type: 'paragraph', text: 'Сурен Джулакян, получивший награды за «Битву экстрасенсов», прокомментировал: «Эта награда — результат усилий всей команды. Мы продолжаем двигаться вперед и делать что-то уникальное для зрителей». Ксения Иванова, победительница в номинации "Лучшая актриса", отметила: «Я горжусь тем, что моя работа была признана. Это вдохновение для будущих ролей и проектов».' },
        { type: 'paragraph', text: 'Фестиваль Crystal Screen продолжает подчеркивать высокие стандарты и достижения в кино и телевидении, предоставляя платформу для развития творческих инициатив. Мы с нетерпением ждем новых талантов и уникальных проектов на следующем фестивале!' },
        
        { type: 'image', src: Articles11_2 },
    ]
    },
    {
      id: 12,
      category: 'Фестивали и премии',
      title: 'Прием заявок на Международный фестиваль кино и телевидения Crystal Screen',
      date: '1 февраля 2020',
      autor: 'Елена Пронина',
      image: Articles12,
      content: [
        { type: 'sub-header', text: 'С радостью объявляем о начале приема заявок на участие в Международном фестивале кино и телевидения Crystal Screen, который вновь соберет творческих профессионалов со всего мира. Фестиваль, проводимый Союзом кинематографистов России с 2018 года, стал знаковым событием в индустрии, отмечая выдающиеся достижения и способствуя развитию кино и телевидения.' },
        { type: 'paragraph', text: 'Crystal Screen — это ежегодное мероприятие, целью которого является признание и награждение выдающихся деятелей в области кино и телевидения. С момента своего основания фестиваль привлекает внимание не только профессионалов, но и любителей кино, предоставляя уникальную платформу для обмена идеями и опытом.' },
        { type: 'paragraph', text: 'Фестиваль пройдет в марте, а церемония награждения запланирована на 15 марта 2020 года. Заявки на участие принимаются с 1 февраля по 1 марта 2020 года. Участвовать могут профессионалы киноиндустрии, производственные компании, телекомпании, а также независимые продюсеры и режиссеры. Заявка должна содержать полное название и описание фильма или программы, информацию о создателях, данные о предыдущих наградах и контактные данные заявителя. Все заявки подаются через официальный сайт фестиваля.' },
        { type: 'header', text: 'Crystal Screen предлагает разнообразные номинации в двух категориях:' },
        { type: 'sub-header', text: 'Кинономинации:' },
        { type: 'list', text: 'Лучший фильм' },
        { type: 'list', text: 'Лучшая режиссура' },
        { type: 'list', text: 'Лучший режиссер короткометражного фильма' },
        { type: 'list', text: 'Лучший актер' },
        { type: 'list', text: 'Лучшая актриса' },
        { type: 'list', text: 'Лучший сценарий' },
        { type: 'list', text: 'Лучшая операторская работа' },
        { type: 'list', text: 'Лучший монтаж' },
        { type: 'list', text: 'Лучшие визуальные эффекты' },
        { type: 'list', text: 'Лучшая музыка для фильма' },
        { type: 'list', text: 'Лучший продюсер' },
        { type: 'sub-header', text: 'Телевизионные номинации:' },
        { type: 'list', text: 'Лучшая телевизионная программа' },
        { type: 'list', text: 'Лучший телевизионный сериал' },
        { type: 'list', text: 'Лучший режиссер телевизионного проекта' },
        { type: 'list', text: 'Лучший сценарий телевизионного проекта' },
        { type: 'list', text: 'Лучшая операторская работа для телевидения' },
        { type: 'list', text: 'Лучший монтаж для телевидения' },
        { type: 'list', text: 'Лучшие визуальные эффекты для телевидения' },
        { type: 'list', text: 'Лучшая музыка для телевизионного проекта' },
        { type: 'list', text: 'Лучший продюсер телевизионного проекта' },
        { type: 'paragraph', text: 'Кроме того, фестиваль включает Гран-при «За вклад в профессию», который вручается за исключительные достижения в кино и телевидении.' },
        { type: 'header', text: 'Процесс отбора заявок' },
        { type: 'paragraph', text: 'Отбор лауреатов включает несколько этапов: сначала осуществляется предварительный отбор заявок, затем — детальный анализ и обсуждение номинированных работ, и, наконец, финальный выбор победителей. Решение жюри является окончательным и не подлежит обжалованию.' },
        { type: 'paragraph', text: 'Жюри фестиваля состоит из признанных экспертов и мастеров кино и телевидения, что обеспечивает высокий уровень оценки заявок. Каждый член жюри осознает важность своего выбора, так как его решения могут оказать значительное влияние на карьеру номинантов.' },
        { type: 'header', text: 'Церемония награждения' },
        { type: 'paragraph', text: 'Церемония вручения наград пройдет в торжественной обстановке, собирая известных деятелей кино и телевидения, представителей СМИ и зрителей. Вручение наград, которое состоится в престижном месте, станет ярким событием, подчеркивающим важность достижения в киноиндустрии.' },
        { type: 'header', text: 'Заключение' },
        { type: 'paragraph', text: 'Международный фестиваль кино и телевидения Crystal Screen продолжает расширять свои горизонты, стремясь стать одним из самых влиятельных и престижных событий в мире кино и телевидения. Мы приглашаем всех желающих участвовать в этом празднике искусства, отмечая достижения и поддержку талантливых деятелей кино и телевидения. Подробности о правилах подачи заявок и номинациях можно найти на официальном сайте фестиваля. Присоединяйтесь к нам, чтобы отметить выдающиеся достижения в мире кино и телевидения!' },
        { type: 'image', src: Articles12_2 },
    ]
    },


];
  
export default articlesData;